import React from "react";
import HTMLReactParser from "html-react-parser";
import { useSelector } from "react-redux";
import { selectLocalizationData } from "../../features/localization/localizationSlice";
import {
  selectBannersData,
  selectBannersError,
} from "../../features/banners/bannersSlice";

/**
 * @param pathname
 * @param topBarRef
 * @returns {null|JSX.Element}
 * @constructor
 */

const TopBar = ({ pathname, topBarRef, className = "" }) => {
  const localization = useSelector(selectLocalizationData);
  const banners = useSelector(selectBannersData) || [];
  const error = useSelector(selectBannersError);

  const regionSpecific = banners
    ? banners.find((banner) => banner.region === localization.region)
    : null;
  const pageSpecific = banners
    ? banners.find((banner) => banner.pathname === pathname)
    : null;

  const banner = pageSpecific || regionSpecific;

  return error || !banner || banner.hidden ? null : (
    <div className={`top-bar${className}`} ref={topBarRef}>
      <div className="container">
        <p className="top-bar__info">
          {HTMLReactParser(banner.value || banner.text)}
        </p>
      </div>
    </div>
  );
};

export default TopBar;
